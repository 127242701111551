
import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Reactmarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import SEO from "../components/seo";
import NotFoundPage from "../pages/404";



export default function ProjectTemplate({data}) {
  return(
    <>
    {
      data.prismicProject.data.details_accessible__ ? 
      <Layout>
      <SEO title={data.prismicProject.data.title.text} />
      <div className="ProjectDetails">
      <article>
      
      <div className="cover-img" style={{backgroundImage: `url(${data.prismicProject.data.cover_image.url})`}}></div>
      {/* <img alt="" className="cover-img" src={data.prismicProject.data.cover_image.url}/> */}
      <span className="ProjectDetailsHeader reveal-in-anim">
        <h1>{data.prismicProject.data.short_description.text}</h1>
      </span>
      <div className="project-details-content-header">
        <div className='presentation-infos'>
            <div className='infos'>
              <div><em>Client</em><br/><span className='tags'>{data.prismicProject.data.client}</span></div>
              <div><em>Service provided</em><br/><span className='tags'>{data.prismicProject.data.role}</span></div>
              <div><em>Release</em><br/><span className='tags'><date>{data.prismicProject.data.release_date}</date></span></div>
              {data.prismicProject.data.project_link.url !== null ? (<div><a target={data.prismicProject.data.project_link.target} href={data.prismicProject.data.project_link.url}>View project</a></div>) : null}
            </div>
        </div>
        <div className='presentation-description'>
          <Reactmarkdown children={data.prismicProject.data.content.html} transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`} rehypePlugins={[rehypeRaw]} className="projectContent"/>
        </div>
      </div>
      {data.prismicProject.data.section.map((document) => (
        <section className={`work-sections ${document.position_img === "Image left - Text right" ? "img-left" : document.position_img === "Image right - Text left" ? "img-right" : document.position_img === "Image bottom - Text top" ? "img-bottom" : document.position_img === "Image top - Text bottom" ? "img-top" : null}`}>
          <div>
            {document.display_title ? (<h2>{document.section_title.text}</h2>) : null}
            {document.display_description ? (<Reactmarkdown children={document.section_description.html} transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`} rehypePlugins={[rehypeRaw]} className="projectContent"/>) : null}
          </div>
          <div className='section-img'>
            {document.display_img && document.image_max_height_100vh === true ? (<img alt="" className="fit-screen-height" src={document.section_img.url}/>) : document.display_img ? (<img alt="" src={document.section_img.url}/>) : null}
          </div>
        </section>
      ))}
      </article>
      <Link className="cta" to="/work">View all the projects</Link>
      </div>
    </Layout>
    : 
    <NotFoundPage/>
    }
    </>
  )
}



export const query = graphql`
  query ProjectTemplate($url: String!) {
    prismicProject(url: {eq:$url}) {
      url
      id
      data {
        details_accessible__
        categorie
        client
        role
        content {
          html
        }
        cover_image {
          url
        }
        project_link {
          url
          target
        }
        release_date
        title {
          text
        }
        short_description{
          text
        }
        section {
          section_title {
            text
          }
          section_img {
            url
          }
          section_description {
            html
          }
          display_description
          display_img
          display_title
          image_max_height_100vh
          position_img
        }
      }
    }
  }
`